import { baseTheme } from "./base"
import { colors } from "./variables"

export const sportswomanTheme = {
  ...baseTheme,
  token: {
    ...baseTheme.token,
    colorPrimary: colors.sportswomanBrand,
    colorInfo: colors.sportswomanBrand,
    colorLink: colors.sportswomanBrand,
    Button: {
      ...baseTheme.token.Button,
      linkColor: colors.sportswomanBrand
    },
    Input: {
      ...baseTheme.token.Input,
      activeBorderColor: colors.sportswomanBrand,
      hoverBorderColor: colors.sportswomanBrand
    }
  }
}
