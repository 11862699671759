import { Typography } from "antd"

const variations = {
  sub: {
    fs: {
      md: 14,
      lg: 16
    },
    fw: 500
  },
  body: {
    fs: {
      md: 14,
      lg: 16
    }
  },
  caption: {
    fs: {
      md: 12,
      lg: 12
    }
  }
}

export const Text = ({ children, variant = "body", size = "md", style, ...rest }) => {
  const variation = variations[variant]
  let styles = {
    fontSize: variation.fs[size],
    fontWeight: variation.fw,
    ...style
  }

  return (
    <Typography.Text style={styles} {...rest}>
      {children}
    </Typography.Text>
  )
}
