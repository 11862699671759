import React, { createContext, useContext, useState } from "react"
import { ConfigProvider } from "antd"
import { sportsmanTheme } from "themes/sportsman"
import { sportswomanTheme } from "themes/sportswoman"
import { themes as themeNames } from "constants/theme"

const themes = {
  [themeNames.sportsman]: sportsmanTheme,
  [themeNames.sportswoman]: sportswomanTheme
}

const ThemeContext = createContext()

export const AntdThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeNames.sportsman)

  const switchTheme = (newTheme) => {
    setTheme(newTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      <ConfigProvider theme={themes[theme]}>{children}</ConfigProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
