import React from "react"
import { Form, Input, Button, Checkbox } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { SocialButtons } from "../SocialButtons"
import { Title, Text } from "ui/Typography"
import { signIn } from "store/auth"

export const SignIn = ({ onLoggedIn }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || "/"
  const loggedInHandler = () => navigate(from, { replace: true })

  const onFinish = (user) => {
    // TODO: error handling
    dispatch(signIn(user)).then(loggedInHandler)
  }

  return (
    <>
      <Title className="justify-center mb-3xl">{t("global.sign_in")}</Title>
      <SocialButtons onLoggedIn={loggedInHandler} />
      <Form name="login" initialValues={{ remember: true }} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("sign_in.email")}
          name="email"
          required={false}
          rules={[
            { required: true, message: t("form_validation.email_required") },
            { type: "email", message: t("form_validation.email") }
          ]}
        >
          <Input placeholder={t("sign_in.email")} />
        </Form.Item>
        <Form.Item
          label={t("sign_in.password")}
          name="password"
          required={false}
          rules={[{ required: true, message: t("form_validation.password_required") }]}
        >
          <Input.Password placeholder={t("sign_in.password")} />
        </Form.Item>
        <Form.Item name="remember_me" valuePropName="checked">
          <div className="flex justify-between">
            <Checkbox value="remember_me">
              <Text type="secondary" variant="body">
                {t("sign_in.remember_me")}
              </Text>
            </Checkbox>
            <Text link>
              <Link to="/auth/forgot-password">{t("sign_in.forgot_password")}?</Link>
            </Text>
          </div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large" disabled={loading}>
            {t("sign_in.email_login")}
          </Button>
        </Form.Item>
      </Form>
      <div className="justify-center">
        <Text type="secondary" variant="body">
          {t("sign_in.dont_have_an_account")}
        </Text>
        <Text className="ml-xs" link>
          <Link to="/auth/sign-up/options">{t("sign_up.register")}</Link>
        </Text>
      </div>
    </>
  )
}
