import React from "react"
import { Form, Button } from "antd"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { SocialButtons } from "../SocialButtons"
import { Title, Text } from "ui/Typography"

export const SignUpOptions = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || "/"
  const loggedInHandler = () => navigate(from, { replace: true })

  return (
    <>
      <Title className="justify-center mb-3xl">{t("sign_up.register")}</Title>
      <SocialButtons onLoggedIn={loggedInHandler} />
      <Form.Item>
        <Link to="/auth/sign-up">
          <Button color="primary" variant="outlined" block size="large">
            {t("sign_up.with_email")}
          </Button>
        </Link>
      </Form.Item>
      <div className="justify-center">
        <Text type="secondary" variant="body">
          {t("sign_up.already_have_account")}
        </Text>
        <Text className="ml-xs" link>
          <Link to="/auth/sign-in">{t("global.sign_in")}</Link>
        </Text>
      </div>
    </>
  )
}
