import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { Text } from "ui/Typography"
import { useGoogleLogin } from "@react-oauth/google"
import { googleSignIn } from "store/auth"
import { useDispatch } from "react-redux"

import googleIcon from "assets/imagesV2/social/google.svg"

export const GoogleLoginButton = ({ onLoggedIn }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(googleSignIn(codeResponse)).then((res) => onLoggedIn(res))
    },
    flow: "auth-code"
  })

  return (
    <Button block color="default" variant="outlined" size="large" onClick={login}>
      <div className="align-center">
        <img className="mr-xs" src={googleIcon} alt={""} />
        <Text bold>{t("sign_in.google_login")}</Text>
      </div>
    </Button>
  )
}
