import React, { useEffect } from "react"
import { RouterProvider } from "react-router-dom"
import { router } from "router"
import Toast from "components/common/Toast"
import Lightbox from "components/common/Lightbox"
import Gradient from "components/Gradient"
import ConfirmModal from "modules/modals/ConfirmModal"
import ShareModal from "modules/modals/ShareModal"
import LoadingContainer from "modules/loader-watchers/LoadingContainer"

import { useBreakpoints } from "modules/window/hooks/useBreakpoints"
import { useDisablePinchZoom } from "hooks/useDisablePinchZoom"
import { dateTime } from "modules/datepicker/helpers"

import useAssume from "hooks/useAssume"

import "stylesheets/bootstrap.scss"
import "stylesheets/application.scss"
import "stylesheets/index.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import "antd/dist/reset.css"

import ThemeProvider from "./context/ThemeProvider"
import { AntdThemeProvider } from "context/AntdThemeContext"
import { useSelector } from "react-redux"

global.dateTime = dateTime

function App() {
  useAssume()
  useBreakpoints()
  useDisablePinchZoom()

  // Update favicon if user is Sportswoman
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  const updateFavicon = () => {
    const favicons = document.querySelectorAll("link[rel~='icon']")

    favicons.forEach((favicon) => {
      if (isSportsman) {
        if (favicon.sizes.value === "192x192") {
          favicon.href = "/new-favicon/new-android-chrome-192x192.png"
        } else if (favicon.sizes.value === "32x32") {
          favicon.href = "/new-favicon/new-favicon-32x32.png"
        } else if (favicon.sizes.value === "16x16") {
          favicon.href = "/new-favicon/new-favicon-16x16.png"
        }
      } else {
        if (favicon.sizes.value === "192x192") {
          favicon.href = "/new-favicon/sportswoman-favicon-192x192.png"
        } else if (favicon.sizes.value === "32x32") {
          favicon.href = "/new-favicon/sportswoman-favicon-32x32.png"
        } else if (favicon.sizes.value === "16x16") {
          favicon.href = "/new-favicon/sportswoman-favicon-16x16.png"
        }
      }
    })
  }

  useEffect(() => {
    updateFavicon()
  }, [isSportsman])

  return (
    <AntdThemeProvider>
      <ThemeProvider>
        <Gradient />
        <Toast />
        <Lightbox />
        <ConfirmModal />
        <ShareModal />
        <LoadingContainer spinnerProps={{ position: "fixed" }}>
          <RouterProvider router={router} />
        </LoadingContainer>
      </ThemeProvider>
    </AntdThemeProvider>
  )
}

export default App
