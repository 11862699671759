import { Form } from "antd";

export const CustomForm = ({ ...props }) => {
    return (
        <Form
            requiredMark={(label, { required }) => (
                <>
                    {label}
                    {required && " *"}
                </>
            )}
            {...props}
        />
    );
};
