import React from "react"
import { Form, Input, Button, Checkbox, Flex } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"
import { CustomInput, PhoneInput } from "ui/Input"
import { spacings } from "themes/variables"
import { CustomForm } from "ui/Form"
import { signUp } from "store/auth"

export const SignUp = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.auth)

    const onFinish = (user) => {
        dispatch(signUp(user)).then((res) => {
            console.log(res);
        })
    }
    
    return (
        <>
            <Title className="justify-center mb-3xl">{t("sign_up.register")}</Title>
            <CustomForm 
                name="sign-up-email"
                layout="vertical"
                onFinish={onFinish}
            >
                <Flex gap={spacings.S}>
                    <Form.Item
                        label={t("sign_up.first_name")}
                        name="first-name"
                        required={true}
                        rules={[
                            { required: true, message: t("form_validation.first_name_required") }
                        ]}
                        className="flex-grow-1"
                    >
                        <CustomInput placeholder={t("sign_up.name")} />
                    </Form.Item>
                    <Form.Item
                        label={t("sign_up.last_name")}
                        name="last-name"
                        required={true}
                        rules={[
                            { required: true, message: t("form_validation.last_name_required") }
                        ]}
                        className="flex-grow-1"
                    >
                        <CustomInput placeholder={t("sign_up.last_name")} />
                    </Form.Item>
                </Flex>
                <Form.Item
                    label={t("sign_up.email")}
                    name="email"
                    required={true}
                    rules={[
                        { required: true, message: t("form_validation.email_required") },
                        { type: "email", message: t("form_validation.email") }
                    ]}
                >
                    <CustomInput placeholder={t("sign_up.email_placeholder")} />
                </Form.Item>
                <Form.Item
                    label={t("sign_up.phone_number")}
                    name="phone-number"
                    required={true}
                    rules={[
                        { required: true, message: t("form_validation.phone_number_required") }
                    ]}
                >
                    <PhoneInput />
                </Form.Item>
                <Form.Item
                    label={t("sign_up.password")}
                    name="password"
                    required={true}
                    rules={[
                        {required: true, message: t("form_validation.password_required")},
                        { min: 8, message: t("form_validation.password_length") }
                    ]}
                >
                    <Input.Password placeholder={t("sign_up.password_placeholder")} />
                </Form.Item>
                <Form.Item
                    name="agreeCheck"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error(t("form_validation.accept_terms_conditions"))),
                        },
                    ]}
                >
                    <Checkbox>
                        <Text type="secondary" variant="body">
                            {t("sign_up.agree_terms")}
                        </Text>
                        <Text className="ml-xs" link>
                            <Link to="">{t("sign_up.terms_conditions")}</Link>
                        </Text>
                        <Text className="ml-xs" link>
                            &
                        </Text>
                        <Text className="ml-xs" link>
                            <Link to="">{t("sign_up.privacy_policy")}</Link>
                        </Text>
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button className="mt-xs mb-xs" type="primary" htmlType="submit" block size="large" disabled={loading}>
                        {t("sign_up.register")}
                    </Button>
                </Form.Item>
            </CustomForm>
            <Flex justify='center' >
                <Text type="secondary" variant="body">
                    {t("sign_up.already_have_account")}
                </Text>
                <Text className="ml-xs" link>
                    <Link to="/auth/sign-in">{t("sign_in.log_in")}</Link>
                </Text>
            </Flex>
        </>
    )
}
